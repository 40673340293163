<template>
	<div style="position: relative;">
		<header id="header" class="d-flex align-items-center" :class="{ 'header-top': isActive }" @mouseleave="leaveLang2()">
			<!-- justify-content-between -->
			<div class="container d-flex align-items-center header-size">
				<div class="d-flex align-items-center justify-content-between">
					<!--  @click="tohome()" -->
					<div class="logo">
						<h1 class="logo">
							<a href="/">
							<img src="../assets/img/logo3.png" alt="贝铂智能logo" />
							</a>
						</h1>
					</div>
				</div>
				<!-- pc -->
				<nav id="navbar" class="navbar" :class="{'navbar-mobile':clickNav,'nav-flex-2':isPc}" v-show="isPc">
					<ul>
						<li v-for="(item,index) in navList" :key="index" :class="[item.children?'dropdown':'',language!='zh'?'navbar-li':'']">
							<a class="nav-link scrollto" v-if="!item.children" @mousemove="hasMouse(item.path,index,1)"
								@mouseleave="hasMouseleave(1)" @click="selectNav(item.path,index)"
								:class="(isSelect === item.path && navIndex==index? 'active' : '')">
								<router-link :to="item.path">{{ item.title }}</router-link>
							</a>
							<a v-if="item.children" class="nav-link scrollto cursor-1" @mousemove="hasMouse(item.path,index,2)"
								@mouseleave="hasMouseleave(2)" @click="childrenClick(item.path,index)"
								:class="(isSelect === item.path && navIndex==index? 'active' : '')">
								{{ item.title }}
								<i class="bi bi-chevron-down" v-if="!(isSelect === item.path? 'active' : '')"></i>
								<i class="bi bi-chevron-up" v-else></i>
							</a>
						</li>
						<div class="navbar-right-box">
							<li class="dropdown dropdown2 navbar-right">
								<a class="nav-link scrollto"  style="width: 80px;cursor: pointer;" :class="hasLang?'theme-color':''" @mousemove="moveLang()">
									<img class="dropdown-la-img" src="../assets/img/icon/la2.png"  v-if="!hasLang"/>
									<img class="dropdown-la-img" src="../assets/img/icon/la.png"  v-else="hasLang"/>
										{{ language=='en'?'EN':'简体' }}
										<i class="bi bi-chevron-down" v-if="!hasLang"></i>
										<i class="bi bi-chevron-up" v-else></i>
								</a>
								<ul class="dropdown-active dropdown-box-ul" @mouseleave="leaveLang()" v-if="hasLang">
									<li class="dropdown-li cursor-1" :class="language=='zh'?'dropdown-li2':''"
										@click="changeLanguage(0)">
										<div class="dropdown-libox" :class="language=='简体'?'dropdown-li2':''">简体</div>
									</li>
									<li class="dropdown-li cursor-1" :class="language=='en'?'dropdown-li2':''"
										@click="changeLanguage(1)">
										<div class="dropdown-box" :class="language=='en'?'dropdown-li2':''">EN</div>
									</li>
								</ul>
							</li>
							<li class="dropdown" style="margin-left: 28px;">
								<div class="login" @click="login()" v-if="!loginState">{{ this.$t('home.Login/Regis') }}</div>
								<div style="cursor: pointer;display: flex;align-items: center;"
								 v-if="loginState" @mousemove="avatarMousemove()" :class="hasAvatar?'avatar-active':''">
									
									<img v-show="!hasAvatar && avatar_update_time==0" class="dropdown-avatar-img" src="../assets/img/login/avatar.png" />
									<img v-show="hasAvatar && avatar_update_time==0" class="dropdown-avatar-img" src="../assets/img/login/avatar2.png" />
									<img v-show="avatar_update_time>0 && uid" class="dropdown-avatar-img" :src="$baseImg+`/bb/${uid}/avatar.png?x-oss-process=image/resize,m_fixed,h_100,w_100&t=${avatar_update_time}`" />
									{{$store.state.globalData.userName}}
									<i  v-show="!hasAvatar" class="bi bi-chevron-down" style="margin-left: 4px;"></i>
									<i  v-show="hasAvatar" class="bi bi-chevron-up" style="margin-left: 4px;"></i>
								</div>
								<div class="avatar-box-view" v-if="hasAvatar" @mouseleave="avatarleave()">
									<li class="dropdown-li cursor-1" :class="[myIndex==index?'dropdown-li2':'',index==myDataList.length-1?'dropdown-li-top':'']"
										@click="changeMylist(item,index)" @mousemove="moveMylist(index)" v-for="(item,index) in myDataList" :key="index">
										<div class="dropdown-libox" :class="myIndex==index?'dropdown-li2 theme-color':''">{{item.name}}</div>
									</li>
								</div>
							</li>
						</div>
					</ul>
					<i class="bi bi-list mobile-nav-toggle" @click="mobileClick()"></i>
				</nav>
				<!-- h5 -->
				<nav id="navbar" class="navbar navbar-h5" :class="{'navbar-mobile':clickNav,'nav-flex-1':!isPc}" v-show="!isPc" @click="changeNav">
					<ul>
						<li v-for="(item,index) in navList" :key="index" :class="item.children?'dropdown dropdown-left':''">
							<a class="nav-link scrollto" v-if="!item.children" @click.stop="selectNav(item.path,index)"
								:class="(isSelect === item.path? 'active' : '')">
								<router-link :to="item.path">{{ item.title }}</router-link>
							</a>
							<div style="display: flex;flex-direction: column;align-items: flex-start;" v-if="item.children">
								<div>
									<a v-if="item.children" class="nav-link scrollto cursor-1" @click.stop="changeH5Pro(item.path)"
										:class="(isSelect === item.path? 'active' : '')">
										{{ item.title }}
										<i class="bi bi-chevron-down"></i>
									</a>
								</div>
								<div v-show="isProlist">
									<div class="pro-h5-list" v-for="(item,index) in solutionCategoryList" :key="index" @click.stop="toUrl(item)">{{item.name}}</div>
								</div>
							</div>
						</li>
					</ul>
					
					<div class="dropdown dropdown2" style="margin-right: 16px;position: relative;" v-if="!isPc && !clickNav">
						<a class="nav-link scrollto" @click.stop="changeLang()" style="width: 80px;">
							<img class="dropdown-la-img"
								src="../assets/img/icon/la2.png" />{{ language=='en'?'EN':'简体' }}<i
								class="bi bi-chevron-down"></i>
						</a>
						<div class="dropdown-active dropdown-box-ul" v-show="isLang" style="position: absolute;top: 37px;right: -29px;z-index: 1;background: #FFF;">
							<li class="dropdown-li cursor-1" :class="language=='zh'?'dropdown-li2':''"
								@click.stop="changeLanguage(0)">
								<div class="dropdown-libox" :class="language=='简体'?'dropdown-li2':''">简体</div>
							</li>
							<li class="dropdown-li cursor-1" :class="language=='en'?'dropdown-li2':''"
								@click.stop="changeLanguage(1)">
								<div class="dropdown-box" :class="language=='en'?'dropdown-li2':''">EN</div>
							</li>
						</div>
					</div>
					<i class="bi bi-list mobile-nav-toggle" @click.stop="mobileClick()" v-if="!clickNav"></i>
				</nav>
			</div>
		</header>
		<div v-if="isPro" class="dropdown-active-pro-view" @mouseleave="ProMouseleave()">
			<div class="pro-list-box container">
				<div class="pro-list-box-1 cursor-1" :class="activeIndex==index?'pro-list-box-2':''" @click="changeProType(index)"
				v-for="(item,index) in solutionCategory" :key="index" @mousemove="changeProType(index)" @mouseleave="leaveProType(index)">{{item.name}}</div>
			</div>
			<div class="pro-list-box2 container">
				<div class="pro-list-box-1-left pro-list-box-1-icon cursor-1" @click="changeLeft()" v-if="solutionCategoryList2.length>5">
					<img src="../assets/img/header/left-1.png" />
				</div>
				<div class="pro-list-box-1-list container" :class="solutionCategoryList.length<5?'pro-list-box-1-list-center':''">
					<div class="pro-goods-box cursor-1" v-show="index<5?true:false" v-for="(item,index) in solutionCategoryList" :key="index" @click="toUrl(item)">
						<div class="pro-goods">
							<img :src="item.pic_url | hasHttp" />
						</div>
						<div class="pro-goods-name">{{item.name}}</div>
					</div>
				</div>
				<div class="pro-list-box-1-right pro-list-box-1-icon cursor-1" @click="changeRight()" v-if="solutionCategoryList2.length>5">
					<img src="../assets/img/header/right-1.png" />
				</div>
			</div>
		</div>
		<div class="header-h"></div>
		<login ref="login"></login>
	</div>
</template>

<script>
	import {
		getSolutionCategory,
		getSolutionCategorylist,
		getUserInfo
	} from "../../common/api";
	import login from '@/components/login.vue'
	export default {
		components: {
			login
		},
		props: {},
		data() {
			//这里存放数据
			return {
				uid:'',
				language: 'zh',
				isPro: false,
				isPc:false,
				isPc2:true,
				isLang:false,
				isActive: false,
				clickNav: false,
				showChiledren: false,
				activeIndex: 0,
				isMouse: false,
				dialogFormVisible:false,
				navIndex:0,
				oldnavIndex:0,
				isLogin:true,
				userName:'',
				myDataList:[
					{url:'/recorderCloud',name:this.$t('login.login22')},
					// {url:'/writing',name:this.$t('login.login23')},
					// {url:'/dialog',name:this.$t('login.login24')},
					{url:'/personalCenter',name:this.$t('login.login25')},
					{url:'',name:this.$t('login.login26')},
				],
				myIndex:-1,
				navList: [{
						id: 1,
						path: '/home',
						title: this.$t('home.home'),
					},
					{
						id: 2,
						path: '/aiMode',
						title: this.$t('home.XiaobeiBigModel'),
					},
					{
						id: 3,
						path: '/aiCapacity',
						title: this.$t('home.AIcapability'),
					},
					{
						id: 4,
						path: '/programme',
						title: this.$t('home.Solution'),
						children: [],
					},
					{
						id: 5,
						path: '/product',
						title: this.$t('home.ProductCenter'),
					},
					// {
					//     id: 4,
					//     path: '/medium',
					//     title: 'OEM/ODM',
					// },
					{
						id: 6,
						path: '/medium',
						title: this.$t('home.MediaCenter'),
					}, {
						id: 7,
						path: '/about',
						title: this.$t('home.AboutUs'),
					}
				],
				isSelect: '/home',
				NewisSelect: '/home',
				solutionCategory:[],
				solutionCategoryList:[],
				solutionCategoryList2:[],
				tabindex:1,
				cuont:0,
				isProlist:false,
				hasAvatar:false,
				hasLang:false,
				loginState:false,
				headerStatus:true,
				avatar_update_time:-1,
			};
		},
		computed: {
			
		},
		watch: {
			'$route'(to, from) {
				this.loginState=localStorage.getItem('loginState')?true:false;
				this.getUserInfoData();
			},
			updtaStatus() {
				this.getUserInfoData();
			},
		},
		created() {
			//console.log(window.innerWidth)
			// this.init();
			if(window.innerWidth<991){
				this.isPc=false;
				this.isPc2=false;
			}else{
				this.isPc=true;
			}
		},
		mounted() {
			console.log("url=" + window.location.pathname)
			this.isSelect = window.location.pathname;
			this.NewisSelect = window.location.pathname;
			this.navList.filter((item)=>{
				if(item.path==this.isSelect){
					this.navIndex=item.id-1
				}
			})
			let lang = localStorage.getItem('lang') || 'zh';
			let langlocale = (lang === 'en' ? 'en' : 'zh');
			this.language = langlocale;
			this.loginState=localStorage.getItem('loginState')?true:false;
			//window.addEventListener('scroll', this.handleScroll)
			window.addEventListener('resize', this.handleResize);
			this.getUserInfoData();
			this.getSolutionCategoryData();
		},
		methods: {
			getUserInfoData(){
				if(this.loginState){
					const params={}
					getUserInfo(params).then((res) => {
						if(res.code==0){
							let percentage=0;
							if(res.data.capacity_info){
								percentage=(res.data.capacity_info.used/(res.data.capacity_info.total/100)).toFixed(0);
							}
							this.$store.commit('updateGlobalData',{
							    userName:res.data.email,
								avatar_update_time:res.data.avatar_update_time,
								spaceInfo:res.data.capacity_info,
								percentage:percentage
							})
							localStorage.setItem('uid', res.data.uid);
							this.userName=res.data.nickname;
							this.uid=res.data.uid;
							this.avatar_update_time=res.data.avatar_update_time;
						}else{
							localStorage.setItem('loginState', '');
							this.$store.commit('updateGlobalData',{
							    userName:'',
								avatar_update_time:'',
								spaceInfo:'',
								percentage:0,
							})
							//this.$message.error(res.msg);
						}
					})
				}
			},
			tohome(){
				this.$router.push('/');
			},
			moveLang(){
				this.hasLang=true;
				this.hasAvatar=false;
			},
			leaveLang(){
				this.hasLang=false;
			},
			leaveLang2(){
				this.hasLang=false;
				this.hasAvatar=false;
			},
			login(){
				this.$nextTick(()=>{
					this.$refs.login.dialogFormVisible=true;
					this.$refs.login.activeTab=0;
					this.$refs.login.init();
				})
			},
			avatarMousemove(){
				this.hasAvatar=true;
			},
			avatarleave(){
				this.hasAvatar=false;
			},
			changeMylist(item,index){
				this.myIndex=index;
				if(item.url==''){
					localStorage.setItem('loginState', '');
					this.loginState=false;
					this.hasAvatar=false;
					this.$router.push('/');
				}else{
					this.$router.push({
						path: item.url,
						query: {},
					})
				}
			},
			moveMylist(index){
				this.myIndex=index;
			},
			changeNav(){
				this.clickNav=!this.clickNav;
			},
			changeH5Pro(){
				this.isProlist=!this.isProlist;
			},
			changeLang(){
				this.isLang=!this.isLang;	
			},
			getSolutionCategoryData(){
				const params={}
				getSolutionCategory(params).then((res) => {
					if(res.code==0){
						this.solutionCategory=res.data;
						this.getSolutionCategoryListData();
					}else{
						this.$message.error(res.msg);
					}
				})
			},
			getSolutionCategoryListData(){
				const params={
					"cid": this.solutionCategory[this.activeIndex].id,
				}
				getSolutionCategorylist(params).then((res) => {
					if(res.code==0){
						this.solutionCategoryList=res.data;
						this.solutionCategoryList2=res.data;
						this.cuont=res.data.length;
					}else{
						this.$message.error(res.msg);
					}
				})
			},
			toUrl(item){
				this.isPro = false;
				this.clickNav=false;
				this.$router.push({
					path: "/programmeDetails",
					query: { id: item.sol_id},
				})
			},
			handleResize() {
				let windowWidth = window.innerWidth;
				let windowHeight = window.innerHeight;
				if(windowWidth<991){
					this.isPc=false;
					this.isPc2=false;
				}else if(windowWidth>1100){
					this.isPc2=true;
					this.isPc=true;
				}else{
					this.isPc=true;
					this.isPc2=true;
				}
			},
			changeLanguage(index) {
				let lang = "zh";
				if (index == 0) {
					this.language = 'zh';
					lang = "zh";
				} else {
					this.language = 'en';
					lang = "en";
				}
				localStorage.setItem('lang', lang);
				this.$i18n.locale = lang;
				window.location.reload();
			},
			childrenClick(dhVal,index) {
				this.isPro = !this.isPro;
				//this.showChiledren = !this.showChiledren;
				this.navIndex=i;
				this.oldnavIndex=i;
				this.isPro = false;
				this.isSelect = dhVal;
				this.NewisSelect = dhVal;
				this.clickNav = false;
				this.$router.push({
					path: dhVal
				})
			},
			ProMouseleave() {
				this.isPro = !this.isPro;
			},
			changeProType(index) {
				this.activeIndex = index;
				this.tabindex=1;
				this.getSolutionCategoryListData();
			},
			leaveProType(){
				// this.tabindex=1;
				// this.getSolutionCategoryListData();
			},
			mobileClick() {
				this.clickNav = !this.clickNav;
			},
			hasMouse(val,i,index) {
				if(index==2){
					this.isPro = true;
				}else{
					this.isPro = false;
				}
				this.navIndex=i;
				this.isSelect = val;
				this.NewisSelect = val;
				this.clickNav = false;
				//this.$router.push({path:val})
			},
			hasMouseleave(index) {
				if(index==1){
					this.isPro = false;
				}
				this.isSelect = window.location.pathname;
				this.NewisSelect = window.location.pathname;
				setTimeout(()=>{
					this.navIndex=this.oldnavIndex;
				},500)
			},
			selectNav(dhVal,i) {
				this.navIndex=i;
				this.oldnavIndex=i;
				this.isPro = false;
				this.isSelect = dhVal;
				this.NewisSelect = dhVal;
				this.clickNav = false;
				this.$router.push({
					path: dhVal
				})
			},
			// 获取页面滚动距离
			handleScroll() {
				let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
				if (scrollTop >= 74) {
					this.isActive = true;
				} else {
					this.isActive = false;
				}
			},
			changeLeft(){
				if(this.tabindex<this.cuont && this.tabindex>1){
					this.tabindex--;
					this.solutionCategoryList=this.getPagelist(this.solutionCategoryList2,this.tabindex,5);
				}else{
					this.$message.warning(this.$t('home.home603'))
				}
			},
			changeRight(){
				if(this.tabindex<Number((this.cuont%5)+1) && this.tabindex<this.cuont){
					this.tabindex++;
					this.solutionCategoryList=this.getPagelist(this.solutionCategoryList2,this.tabindex,5);
				}else{
					this.$message.warning(this.$t('home.home603'))
				}
			},
			getPagelist(arr,pageIndex, pageSize) {
	            let start = (pageIndex - 1) * pageSize;
	            let end = start + pageSize
	            return arr.slice(start, end)
        	}
		},
	}
</script>
<style lang="scss" scoped>
	.navbar-h5 li{
		margin: 0 20px;
		padding: 0 !important;
		border-bottom: 1px solid rgba(243, 243, 243, 1);
		.dropdown-left{
			padding: 10px 0!important;
		}
	}
	.navbar-h5 .dropdown-left{
		padding: 10px 0!important;
	}
	.navbar-h5 a{
		padding: 10px 0 !important;
	}
	
	.pro-h5-list{
		font-family: Source Han Sans CN, Source Han Sans CN;
		font-weight: 400;
		font-size: 14px;
		color: #999999;
		line-height: 28px;
		text-align: left;
		font-style: normal;
		text-transform: none;
		padding: 6px 0;
	}
	.dropdown-li{
		margin: 0!important;
	}
	
	.nav-flex-1{
		flex: 1;
		// margin-left: 20px;
		display: flex;
		justify-content: flex-end;
	}
	.dropdown-left{
		margin-left: 20px;
	}
	.nav-flex-2{
		flex: 1;
		display: flex;
		justify-content: flex-start;
	}
	.logo-name {
		width: 88px;
		height: 36px;
		font-family: Source Han Sans CN, Source Han Sans CN;
		font-weight: bold;
		font-size: 24px;
		color: #333333;
		line-height: 28px;
		text-align: left;
		font-style: normal;
		text-transform: none;
		margin-left: 8px;
	}

	.navbar-right {
		margin-left: 3.5rem;
	}

	.login {
		padding: 6px 18px;
		height: 36px;
		// padding: 6px 1.125rem;
		// height: 2.25rem;
		border-radius: 4px 4px 4px 4px;
		border: 1px solid #D9D9D9;

		display: flex;
		align-items: center;
		justify-content: center;

		font-family: Source Han Sans CN, Source Han Sans CN;
		font-weight: 400;
		font-size: 16px;
		color: #333333;
		line-height: 19px;
		text-align: left;
		font-style: normal;
		text-transform: none;
		cursor: pointer;
	}

	.dropdown-box-ul {
		padding: 8px 0;
		box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.15);
		border-radius: 4px 4px 4px 4px;
	}

	.dropdown-la-img {
		width: 16px;
		height: 16px;
		margin-right: 4px;
	}

	.dropdown2 ul {
		top: 45px !important;
		left: 0 !important;
	}

	.dropdown-li {
		width: 136px;
		height: 53px;

		display: flex;
		align-items: center;
		justify-content: center;
	}

	.dropdown-box {
		width: 136px;
		height: 53px;
		background: #FFF;
		border-radius: 0px 0px 0px 0px;
		display: flex;
		align-items: center;
		justify-content: center;

		font-family: Source Han Sans CN, Source Han Sans CN;
		font-weight: 400;
		font-size: 14px;
		color: #333333;
		line-height: 16px;
		text-align: left;
		font-style: normal;
		text-transform: none;
	}

	/* .dropdown-li:hover{
		background: #E6F4F4;
		
	} */
	.dropdown-li2 {
		background: #E6F4F4;

	}

	.dropdown-active-pro-view {
		width: 100%;
		height: 300px;
		background-color: #FFFFFF;

		position: fixed;
		left: 0;
		right: 0;
		top: 74px;
		z-index: 99;
		padding: 32px 0 44px;

		.pro-list-box {
			display: flex;
			align-items: center;
			justify-content: center;

			.pro-list-box-1 {
				height: 35px;
				border-radius: 4px 4px 4px 4px;
				font-family: Source Han Sans CN, Source Han Sans CN;
				font-weight: 400;
				font-size: 18px;
				color: #333333;
				line-height: 21px;
				text-align: center;
				font-style: normal;
				text-transform: none;

				display: flex;
				align-items: center;
				justify-content: center;
				margin-left: 60px;
				padding: 4px 16px;
			}

			.pro-list-box-2 {
				background: #10B1A7;
				color: #FFF;
			}
		}

		.pro-list-box2 {
			height: 157px;
			margin-top: 32px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			position: relative;

			.pro-list-box-1-left {
				position: absolute;
				left: 0px;
			}

			.pro-list-box-1-right {
				position: absolute;
				right: 0px;
			}

			.pro-list-box-1-icon {
				width: 60px;
				height: 60px;

				img {
					width: 60px;
					height: 60px;
				}
			}
			.pro-list-box-1-list-center{
				justify-content: center;
			}
			.pro-list-box-1-list {
				display: flex;
				padding: 0 100px;
				overflow: hidden;

				.pro-goods-box {
					display: flex;
					align-items: center;
					flex-direction: column;
					margin-right: 41px;

					.pro-goods {
						width: 180px;
						height: 120px;
						border-radius: 0px 0px 0px 0px;
						img{
							width: 180px;
							height: 120px;
						}
					}

					.pro-goods-name {
						font-family: Source Han Sans CN, Source Han Sans CN;
						font-weight: 400;
						font-size: 14px;
						color: #333333;
						line-height: 16px;
						text-align: left;
						font-style: normal;
						text-transform: none;
						margin-top: 16px;
					}
				}
			}
		}
	}
	
	.dropdown-avatar-img{
		width: 24px;
		height: 24px;
		border-radius: 50%;
		margin-right: 8px;
	}
	.avatar-active{
		color: #10B1A7;
	}
	.avatar-box-view{
		display: block;
		position: absolute;
		left: 28px;
		top: calc(200%);
		margin: 0;
		z-index: 99;
		background: #fff;
		box-shadow: 0px 4px 32px 0px rgba(0,0,0,0.15);
		transition: 0.3s;
		border-radius: 4px 4px 4px 4px;
		padding: 8px 0;
	}
	.dropdown-li-top{
		border-top: 1px solid #E5E5E5;
	}
</style>