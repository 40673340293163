export default {
	lang:'zh',
	home:{
		"home": '首页',
		"XiaobeiBigModel": '小贝大模型',
		"AIcapability": 'AI能力',
		"Solution": '解决方案',
		"ProductCenter": '产品中心',
		"MediaCenter": '媒体中心',
		"AboutUs": '关于我们',
		"Login/Regis": '登录/注册',
		
		"home1": '核心能力',
		"home2": '客服电话',
		"home3": '联系电话',
		"home14": 'AI赋能，以智能硬件为载体，提供全栈能力和服务',
		"home15": '拼图算法',
		"home16": 'OCR识别',
		"home17": 'AI智能',
		"home18": '语音识别',
		"home19": '权威词典',
		"home20": '文本翻译',
		"home21": '拍照翻译',
		"home22": '全科答疑',
		"home23": '视频课程',
		"home24": '小贝AI大模型',
		"home25": '技术是第一生产力',
		"home26": '技术人员',
		"home27": '研发投入',
		"home28": '专利数量',
		"home29": '(占比)',
		"home30": '(亿元)',
		"home31": '(项)',
		"home32": '解决方案',
		"home33": '面对办公、教育、车盒多场景应用需求，贝铂基于自身的研发能力和服务理念，为客户提供一站式解决方案',
		"home34": '相关解决方案',
		"home35": '相关产品能力',
		"home36": '全栈服务',
		"home37": 'AI赋能，以智能硬件为载体，提供全栈能力和服务',
		"home38": '研发',
		"home39": '生产',
		"home40": '质量管理',
		"home41": '售后支持',
		"home42": '专业软硬件研发团队，多年OCR、语音识别、翻译、AI、人机交互研发能力',
		"home43": '源头工厂，稳定供应链，严格智能工艺制作',
		"home44": '质检人员环环把关，导入ISO9001管理体系',
		"home45": '在国内外建立专业售后支持团队，及时响应，重点解决，一对一服务。',
		"home46": '资质认证',
		"home47": '100多项国内外专利',
		"home48": '新闻资讯',
		"home49": '了解我们最新动态',
		"home50": '查看详情',
		"home51": '合作伙伴',
		"home52": '聚焦人工智能硬件市场，提供专业OEM/0DM服务',
		"home53": '合作咨询',
			
		"home54": '智慧办公',
		"home55": '智慧教育',
		"home56": '智慧车盒',
		
		"home57": '语音识别',
		"home58": '拼图算法',
		"home59": 'OCR识别',
		"home60": '语音合成',
		"home61": '文本翻译',
		"home62": '图片翻译',
		"home63": '语音翻译',
		"home64": '自然语言处理技术',
		
		
		"home571": 'OCR识别',
		"home581": '语音识别 ',
		"home591": '语音翻译',
		"home601": '语音合成',
		"home611": '权威词典',
		"home621": '全科课程',
		"home631": '全科答疑',
		"home641": '自然语言处理技术',
		
		"home572": '语音识别',
		"home582": '语音合成 ',
		"home592": '语音翻译',
		"home602": '自然语言处理技术',
		"home603": '没有更多',
		
	},
	consult:{
		"tilte":"我们将竭诚为您提供全方位的商务合作服务",
		"subtilte":"请留下您的联系方式，我们将尽快与您联系",
		"name":"请输入您的姓名",
		"phone":"请输入您的手机",
		"mailbox":"请输入您的邮箱",
		"mailboxerr":"邮箱格式错误",
		"company":"请输入您的公司",
		"desc":"请输入您的需求",
		"but":"确认提交",
		"region":"中国",
		"select":"请选择",
		"submitSuccess":"提交成功",
		"submitError":"提交失败",
	},
	ProDetails:{
		"ProDetails1": '定制流程',
		"ProDetails2": '聚焦人工智能硬件市场，提供专业OEM/0DM服务',
	},
	aiCapacity:{
		"aiMode1": 'AI能力',
		"aiMode2": '基于大数据、知识图谱、机器学习、语言学等技术，我们不断锐意创新， 潜心钻研AI核心技术和智能硬件应用落地。',
		"aiMode3": '合作咨询',
		"aiMode4": '核心能力',
		"aiMode5": 'AI赋能，以智能硬件为载体，提供全栈能力和服务',
		"aiMode6": '拼图算法',
		"aiMode7": '将零碎小图合并成大图识别，支持50+ 语种',
		"aiMode8": 'OCR识别',
		"aiMode9": '通过图像处理和模式识别技术提取文字。支持50+ 语种',
		"aiMode10": '语音识别',
		"aiMode11": '将长短音频精准识别成文字。支持130+ 语种',
		"aiMode12": '语音合成',
		"aiMode13": '将文字转化为自然流畅的人声。支持130+ 语种',
		"aiMode14": '文本翻译',
		"aiMode15": '将文本从目标语言转成为指定语言。支持130+ 语种',
		"aiMode16": '图片翻译',
		"aiMode17": '图片文字的识别与翻译。支持130+ 语种',
		"aiMode18": '语音翻译',
		"aiMode19": '将长短音频精准识别并翻译。支持130+ 语种',
		"aiMode20": '自然语言处理技术',
		"aiMode21": '基于大数据、知识、语言技术理解实现问答、对话等应用',
		"aiMode22": '词典权威',
		"aiMode23": '与权威机构深度合作，提供牛津、朗文、各小语等正版词典',
		"aiMode24": '视频课程',
		"aiMode25": '全科视频课程，教材同步更新，适合预习、复习',
		"aiMode26": '全科答疑',
		"aiMode27": '全科答疑解译，举一反三训练，提高学习效率',
		"aiMode28": '小贝AI大模型',
		
		"aiMode29": '多模交互',
		"aiMode30": '文本、语音、视频多模交互',
		"aiMode31": '代码能力',
		"aiMode32": '代码生成、解释、纠错、测试',
		"aiMode33": '文本生成',
		"aiMode34": '作文、营销、新闻稿等生成',
		"aiMode35": '全科能力',
		"aiMode36": '全学段学科出题、解题',
		"aiMode37": '语言理解',
		"aiMode38": '摘要提取、语法检查、情感分析',
		
		"aiMode39": '知识问答',
		"aiMode40": '生活、工作、医学和历史知识',
		"aiMode41": '逻辑推理',
		"aiMode42": '思维、科学、常识推理',
		"aiMode43": '多语言',
		"aiMode44": '支持 100 多种语言交互',
		"aiMode45": '技术优势',
		"aiMode46": '2亿精准语料',
		"aiMode47": '广泛应用于智能办公、智能教育、智能车盒',
		"aiMode48": '全球协同技术',
		"aiMode49": '超低延时  安全稳定   智能调度 链路稳定',
		"aiMode50": '安全加密体系',
		"aiMode51": '国际领先的云-管-端-数四维加密体系，全过程加密',
	},
	aiMode:{
		"aiMode1": '小贝AI大模型',
		"aiMode2": '基于大规模神经网络训练的自然语言处理模型，具有自然语言理解、自然语言生成，对话交互、知识问答、文学创作等多种能力。释放创造力，流简化工作流，为智能硬件赋能。',
		"aiMode3": '合作咨询',
		"aiMode4": '多元化能力',
		"aiMode5": 'AI赋能，以智能硬件为载体，提供全栈能力和服务',
		"aiMode6": '多模交互',
		"aiMode7": '文本、语音、视频多模交互',
		"aiMode8": '代码能力',
		"aiMode9": '代码生成、解释、纠错、测试',
		"aiMode10": '文本生成',
		"aiMode11": '作文、营销、新闻稿等生成',
		"aiMode12": '全科能力',
		"aiMode13": '全学段学科出题、解题',
		"aiMode14": '语言理解',
		"aiMode15": '摘要提取、语法检查、情感分析',
		"aiMode16": '知识问答',
		"aiMode17": '生活、工作、医学和历史知识',
		"aiMode18": '逻辑推理',
		"aiMode19": '思维、科学、常识推理',
		"aiMode20": '多语言',
		"aiMode21": '支持 100 多种语言交互',
		"aiMode22": '我们的优势',
		
		"aiMode23": '拟人声语音交互',
		"aiMode24": '模拟人类语音特征、语调和语言风格，让人机对话更加自然和亲切，提升用户体验',
		"aiMode25": '一键智能写作',
		"aiMode26": '面向刚需场景，打造“高效、易用、智能”的办公效率工具。AI一键写作、校准，让工作总结与汇报轻松高效，AI时代的效率利器',
		"aiMode27": '全场景智能助手',
		"aiMode28": '集成了多种功能和服务的智能助手应用，为用户提供全方位的支持和便利',
		"aiMode29": '快速响应',
		"aiMode30": '高效处理',
		"aiMode31": '采用流式的接口设计,首帧响应最快可达毫秒级。借助高效算法与架构，能在极短时间处理大量用户需求',
		"aiMode32": '多元场景',
		"aiMode33": '持续进化',
		"aiMode34": '提供包括语言理解、知识问答、代码编写、逻辑推理、数学解题等多元能力,持续从海量数据和知识中学习与进化',
		"aiMode35": '灵活应用',
		"aiMode36": '个性定制',
		"aiMode37": '提供丰富的参数设置，可以实现个性化的模型体验。针对企业级的定制化需求，可以提供专属的模型解决方案',
		"aiMode38": '服务稳定',
		"aiMode39": '安全可靠',
		"aiMode40": '云服务达到等保三级标准,并采用千亿级流量的公有云架构，结合数据加密与访问控制等多重手段确保用户隐私安全',
	},
	Product:{
		"Product1": '产品中心',
		"Product2": '办公、教育、车载智能产品，支持订购、功能定制',
		"Product3": '合作咨询',
	},
	medium:{
		"medium1": '媒体中心',
		"medium2": '贝铂最新消息，看这里',
		"medium3": '合作咨询',
	},
	about:{
		"about1": '了解贝铂',
		"about2": '办公、教育、车盒等智能硬件方案商',
		"about3": '合作咨询',
		"about4": '公司简介',
		"about5": '资质认证',
		"about6": '100多项国内外专利',
		"about7": '深圳市贝铂智能科技有限公司',
		"about8": '创立于2015年，立足中国“智造”中心深圳，是一家具有前瞻眼光和创新精神的高新技术企业。',
		"about9": '公司具有强大的软件、硬件研发实力，通过输出AI技能如语音技术、图像OCR技术、车机交互技术、云服务等赋能人工智能硬件，已取得相关商标专利100多项。落地产品如智能翻译机、智能录音笔、智能会议宝、智能扫描笔、智能车盒等，2022年相关产品单年出货量超过百万台。',
		"about10": '贝铂智能专注于人工智能软件与硬件的研发、生产和销售，在日本、欧美等国家拥有多个战略合作伙伴，并建立了强大的市场团队和技术支持团队。在帮助人类跨越语言障碍领域，是全球领先的智能翻译设备解决方案提供商。',
	},
	footer:{
		"AboutUs": '关于我们',
		"CollaborativeConsulting": '合作咨询',
		"Businesscooperation": '商务合作',
		"phone": '联系电话：+86 13660736734',
		"tel": '0755 27189290',
		"address": '深圳市龙华区大浪街道陶元社区元芬工业区F栋201',
		"copyright": '版权所有  ©  深圳市贝铂智能科技有限公司',
		"icp": '粤ICP备2024241358号-1',
		
		"footer2":'欢迎注册',
		"footer3":'已有账号？',
		"footer4":'请登录',
	},
	login:{
		"login1":'密码登录',
		"login2":'短信登录',
		"loginTab3":'邮箱登录',
		"login3":'邮箱',
		"login4":'登录密码',
		"login5":'手机号',
		"login6":'请选择',
		"login7":'手机验证码',
		"login8":'登录',
		"login9":'忘记密码',
		"login10":'注册',
		"login11":'登录即表示您同意',
		"login12":'服务协议',
		"login13":'隐私协议',
		"login14":'获取',
		"login15":'中国',
		"login16":'请输入账号',
		"login17":'请输入密码',
		"login18":'登录成功!',
		"login19":'请输入验证码!',
		"login20":'发送成功!',
		"login21":'和',
		
		"login22":'录音笔云盘',
		"login23":'我的写作',
		"login24":'我的对话',
		"login25":'账号安全',
		"login26":'退出登录',
		
		
		
		"login27":'邮箱找回密码',
		"login28":'邮箱',
		"login29":'邮箱验证码',
		"login30":'设置登录密码（6-16位字符）',
		"login31":'再次输入登录密码',
		"login32":'提交',
		"login33":'返回登录',
		"login34":'修改成功!',
	},
	register:{
		"register1":'手机号',
		"register2":'请输入您的手机',
		"register3":'请选择',
		"register4":'手机验证码',
		"register5":'设置密码',
		"register6":'请输入验证码',
		"register7":'6-16位字符',
		"register8":'密码确认',
		"register9":'邮箱',
		"register10":'请输入邮箱',
		"register11":'邮箱验证码',
		"register12":'请输入验证码',
		"register13":'我已阅读并同意',
		"register14":'请阅读并勾选同意协议',
		"register15":'手机号格式错误 / 手机号已注册',
		"register16":'手机验证码错误',
		"register17":'请输入邮箱 / 邮箱格式错误',
		"register18":'邮箱格式错误',
		"register19":'邮箱验证码错误',
		"register20":'密码请设置6-16位字符',
		"register21":'密码格式错误',
		"register22":'两次输入的密码不一致，请重新输入',
		"register23":'请输入密码',
		"register24":'注册成功!',
		"register25":'验证码错误',
	},
	recorder:{
		"recorder1":'录音笔云盘',
		"recorder2":'云空间容量',
		"recorder3":'最近文件',
		"recorder4":'名称',
		"recorder5":'音频时长',
		"recorder6":'文件大小',
		"recorder7":'创建时间',
		"recorder8":'操作',
		"recorder9":'云空间说明',
		"recorder10":'音频',
		"recorder11":'OCR文件',
		"recorder12":'1.云空间是用来做什么的?',
		"recorder13":'在录音笔上录音完成后，您可以上传音频和转写文件至云空间，即使录音笔不在身边，您也可以进入云空间查看音频。',
		"recorder14":'2.如何上传云空间?',
		"recorder15":'在录音笔上进入【我的文件】，选中目标文件长按出现选项，点击“上传”，即可将文件上传至云空间。',
		"recorder16":'3.如何查看/管理云空间文件?',
		"recorder17":'登录贝铂官网，选择“我的-录音笔云盘”，就可以查看已经上传的录音笔文件。您可以选择目标文件进行下载、删除等操作。您可以通过删除云空间文件清理云空间的存储哦!',
		
		"recorder18":'未转写',
		"recorder19":'查看摘要',
		"recorder20":'讲话人',
		"recorder21":'编辑讲话人名称',
		"recorder22":'确定',
		"recorder23":'下载',
		"recorder24":'下载内容：',
		"recorder25":'下载设置：',
		"recorder26":'显示说话人',
		"recorder27":'取消',
		"recorder28":'链接分享',
		"recorder29":'获得链接的人都可以访问，仅支持阅读，链接7天有效',
		"recorder30":'复制链接',
		"recorder31":'扫一扫，快速查看',
		"recorder32":'智能整理',
		"recorder33":'区别说话人',
		"recorder34":'按照讲话人角色，智能区分转写文本',
		"recorder35":'智能口语规整',
		"recorder36":'自动过滤语气词、重复词、停顿词',
		"recorder37":'非人声过滤',
		"recorder38":'播放时自动跳过无人声，噪声的音频',
		"recorder39":'请选择',
		"recorder40":'文档',
		"recorder41":'音频',
		"recorder42":'文档+音频',
		
		"recorder43":'提示',
		"recorder44":'此操作将删除该文件, 是否继续?',
		"recorder45":'确定',
		"recorder46":'取消',
		"recorder47":'删除成功!',
		"recorder48":'已取消删除',
		"recorder49":'暂无标记',
		"recorder50":'正在保存…',
	},
	personal:{
		"personal1":'账户设置',
		"personal2":'个人信息',
		"personal3":'头像',
		"personal4":'上传文件',
		"personal5":'支持JPG、JPEG、GIF、PNG 风格，支持 2M 以内的图片',
		"personal6":'昵称',
		"personal7":'请输入昵称',
		"personal8":'性别',
		"personal9":'先生',
		"personal10":'女士',
		"personal11":'保密',
		
		"personal12":'生日',
		"personal13":'请选择生日',
		"personal14":'职业',
		"personal15":'请选择职业',
		"personal16":'请输入职业（非必填）',
		"personal17":'国家',
		"personal18":'请选择国家',
		"personal19":'保存',
		"personal20":'个人信息',
		"personal21":'账户安全',
		"personal22":'只支持2M以内的图片',
		"personal23":'上传成功!',
		"personal24":'修改成功!',
		
		"personal25":'账户安全',
		"personal26":'登录密码',
		"personal27":'修改',
		"personal28":'电子邮箱',
		"personal29":'更换',
		"personal30":'账号注销',
		"personal32":'确定注销账户吗？',
		"personal33":'注销后，您在帐户下的全部内容将全部清除，请谨慎操作。',
		"personal34":'取消',
		"personal35":'确定',
		"personal36":'身份验证',
		"personal37":'为确认是您本人操作，请通过邮箱验证码完成身份验证',
		"personal38":'邮箱',
		"personal39":'验证码',
		"personal40":'请输入验证码',
		"personal41":'下一步',
		"personal42":'设置新邮箱',
		"personal43":'邮箱',
		"personal44":'请输入新邮箱',
		"personal45":'验证码',
		"personal46":'请输入验证码',
		"personal47":'确定',
		"personal48":'设置新登录密码',
		"personal49":'新登录密码',
		"personal50":'请输入新密码',
		"personal51":'确认新密码',
		"personal52":'请再次输入新密码',
		"personal53":'确定',
		"personal54":'获取',
		
		"personal55":'邮箱格式错误',
		"personal56":'更换成功!',
		"personal57":'密码请设置6-16位字符',
		"personal58":'两次输入的密码不一致',
		"personal59":'修改成功!',
		"personal60":'注销成功!',
	}
}