<template>
	<div id="app">
		<!-- <Topbar></Topbar> -->
		<Header v-show="haslogin"></Header>
		<headerLogin v-show="!haslogin"></headerLogin>
		<section class="section">
			<router-view />
		</section>
		<Footer :isActive="isActive"></Footer>
	</div>
</template>
<script>
	import Footer from '@/components/Footer.vue'
	import Header from '@/components/Header.vue'
	import headerLogin from '@/components/headerLogin.vue'
	// import Topbar from '@/components/Topbar.vue'
	export default {
		name: '',
		components: {
			Header,
			Footer,
			headerLogin
		},
		data() {
			return {
				isActive: false,
				haslogin: true,
			}
		},
		watch: {
		    '$route'(to, from) {
				// 路由变化时的处理逻辑
				if(to.path=='/register'){
					this.haslogin=false;
				}else{
					this.haslogin=true;
				}
		    }
		},
		created() {
			
		},
		mounted() {
			
		},
		methods: {
			
		},
	}
</script>
<style>
	.section{
		min-height: calc( 100vh - 74px );
	}
	::v-deep .el-popover{
		width: 125px !important;
		min-width: 125px !important;
	}
	.popperClass{
		width: 125px !important;
		min-width: 125px !important;
	}
	
	.popperClass2{
		width: 185px !important;
		min-width: 185px !important;
		padding: 0 !important;
		margin-left: 20px;
		margin-bottom: 20px !important;
	}
</style>