<template>
	<div style="position: relative;">
		<header id="header" class="header-nav">
			<div class="container header-size header-top">
				<div class="d-flex align-items-center justify-content-between">
					<!--  @click="tohome()" -->
					<div class="logo">
						<a href="/">
						<!-- <img src="https://vormor.cn/logo2.png"  alt /> -->
						<img src="../assets/img/logo3.png" alt="贝铂智能logo" />
						</a>
					</div>
					<div class="logo-welcome">
						{{$t('footer.footer2')}}
					</div>
				</div>
				<div class="logo-not-logged">
					{{$t('footer.footer3')}}<span style="color: #10B1A7;cursor: pointer;" @click="toLogin">{{$t('footer.footer4')}}</span>
					<i class="el-icon-arrow-right" style="color: #10B1A7;"></i>
				</div>
			</div>
		</header>
		<div class="header-h"></div>
		<login ref="login"></login>
	</div>
</template>

<script>
	import login from '@/components/login.vue'
	export default {
		name: 'Register',
		components: {
			login
		},
		props: {},
		data() {
			return{
				headerStatus:false,
			}
		},
		methods:{
			tohome(){
				this.$router.push('/');
			},
			toLogin(){
				this.$nextTick(()=>{
					this.$refs.login.dialogFormVisible=true;
					this.$refs.login.init();
				})
			}
		}
	}
</script>

<style>
	.header-nav{
		display: flex;
		box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.1);
	}
	.header-top{
		display: flex;
		justify-content: space-between !important;
	}
	.logo-welcome{
		font-family: Source Han Sans CN, Source Han Sans CN;
		font-weight: 400;
		font-size: 20px;
		color: #333333;
		line-height: 23px;
		text-align: left;
		font-style: normal;
		text-transform: none;
		border-left: 1px solid #E5E5E5;
		padding-left: 18px;
		margin-left: 18px;
	}
	.logo-not-logged{
		display: flex;
		align-items: center;
		
		font-family: Source Han Sans CN, Source Han Sans CN;
		font-weight: 400;
		font-size: 14px;
		color: #999999;
		line-height: 16px;
		text-align: left;
		font-style: normal;
		text-transform: none;
	}
</style>