export default {
	lang:'en',
	home:{
		"home": 'Home',
		"XiaobeiBigModel": 'Xiaobei Big Model',
		"AIcapability": 'AI capability',
		"Solution": 'Solution',
		"ProductCenter": 'Product Center',
		"MediaCenter": 'Media Center',
		"AboutUs": 'About Us',
		"Login/Regis": 'Login/Regis',
		
		"home1": 'Core competencies',
		"home2": 'tel',
		"home3": 'phone',
		"home14": 'AI empowerment, using intelligent hardware as a carrier, providing full stack capabilities and services',
		"home15": 'Puzzle algorithm',
		"home16": 'OCR recognition',
		"home17": 'AI Intelligence',
		"home18": 'speech recognition',
		"home19": 'Authoritative Dictionary',
		"home20": 'Text translation',
		"home21": 'Photo translation',
		"home22": 'General Q&A',
		"home23": 'Video courses',
		"home24": 'Xiaobei AI Big Model',
		"home25": 'Technology is the primary productive force',
		"home26": 'Technical personnel',
		"home27": 'R&D investment',
		"home28": 'Number of patents',
		"home29": '(Proportion)',
		"home30": '(RMB100mn)',
		"home31": '(term)',
		"home32": 'Solution',
		"home33": 'Faced with the application needs of multiple scenarios such as office, education, and car boxes, Beibo provides customers with a one-stop solution based on its own research and development capabilities and service philosophy',
		"home34": 'Related solutions',
		"home35": 'Related product capabilities',
		"home36": 'Full stack service',
		"home37": 'AI empowerment, using intelligent hardware as a carrier, providing full stack capabilities and services',
		"home38": 'research',
		"home39": 'production',
		"home40": 'quality control',
		"home41": 'After sales support',
		"home42": 'A professional software and hardware R&D team with years of experience in OCR, speech recognition, translation, AI, and human-computer interaction research and development capabilities',
		"home43": 'Source factory, stable supply chain, strict intelligent process production',
		"home44": 'Quality inspectors conduct environmental checks and introduce the ISO9001 management system',
		"home45": 'Establish a professional after-sales support team both domestically and internationally, respond promptly, focus on solutions, and provide one-on-one service.',
		"home46": 'Qualification certification',
		"home47": 'Over 100 domestic and foreign patents',
		"home48": 'News and information',
		"home49": 'Stay updated on our latest updates',
		"home50": 'View details',
		"home51": 'Partners',
		"home52": 'Focusing on the artificial intelligence hardware market and providing professional OEM/ODM services',
		"home53": 'Consult',
		"home54": 'Smart office',
		"home55": 'Smart education',
		"home56": 'Smart car box',
		// "home57": 'speech recognition',
		// "home58": 'phonetic transcription',
		// "home59": 'Text recognition',
		// "home60": 'Photo translation',
		
		
		"home57": 'Speech recognition',
		"home58": 'Puzzle algorithm',
		"home59": 'OCR recognition',
		"home60": 'Speech synthesis',
		"home61": 'Text translation',
		"home62": 'Image translation',
		"home63": 'Voice translation',
		"home64": 'Language processing technology',
		
		
		"home571": 'OCR recognition',
		"home581": 'Speech recognition ',
		"home591": 'Speech translation',
		"home601": 'Speech synthesis',
		"home611": 'Authoritative dictionaries',
		"home621": 'General courses',
		"home631": 'General Q&A',
		"home641": 'Language processing technology',
		
		"home572": 'Speech recognition',
		"home582": 'Speech synthesis ',
		"home592": 'Speech translation',
		"home602": 'Language processing technology',
		"home603": 'No more',
	},
	consult:{
		"tilte":"We will wholeheartedly provide you with comprehensive business cooperation services",
		"subtilte":"Please leave your contact information, we will contact you as soon as possible",
		"name":"Please enter your name",
		"phone":"Please enter your phone number",
		"mailbox":"Please enter your email address",
		"mailboxerr":"Email format error",
		"company":"Please enter your company",
		"desc":"Please enter your requirements",
		"but":"Confirm",
		"region":"CN",
		"select":"请选择",
		"submitSuccess":"Submitted successfully",
		"submitError":"Submission failed",
	},
	ProDetails:{
		"ProDetails1": 'Customized process',
		"ProDetails2": 'Focusing on the artificial intelligence hardware market and providing professional OEM/ODM services',
	},
	aiCapacity:{
		"aiMode1": 'Xiaobei AI Big Model',
		"aiMode2": 'A natural language processing model trained on large-scale neural networks, with various abilities such as natural language understanding, natural language generation, dialogue interaction, knowledge Q&A, and literary creation. Unleash creativity, streamline workflows, and empower intelligent hardware.',
		"aiMode3": 'Consult',
		"aiMode4": 'Diversification capability',
		"aiMode5": 'AI empowerment, using intelligent hardware as a carrier, providing full stack capabilities and services',
		"aiMode6": 'Multimode interaction',
		"aiMode7": 'Multi mode interaction of text, voice, and video',
		"aiMode8": 'codability',
		"aiMode9": 'Code generation, interpretation, error correction, testing',
		"aiMode10": 'Text generation',
		"aiMode11": 'Generation of essays, marketing, press releases, etc',
		"aiMode12": 'General ability',
		"aiMode13": 'Setting and solving questions for all subjects in the entire academic stage',
		"aiMode14": 'language understanding ',
		"aiMode15": 'Abstract extraction, grammar check, sentiment analysis',
		"aiMode16": 'Knowledge Q&A',
		"aiMode17": 'Knowledge of life, work, medicine, and history',
		"aiMode18": 'logical reasoning',
		"aiMode19": 'Thinking, science, common sense reasoning',
		"aiMode20": 'Multilingual',
		"aiMode21": 'Supports interaction in over 100 languages',
		"aiMode22": 'Our strengths',
		"aiMode23": 'Deeply cooperate with authoritative institutions to provide authentic dictionaries such as Oxford, Longman, and various languages',
		"aiMode24": 'Video courses',
		"aiMode25": 'Comprehensive video courses with synchronized updates of textbooks, suitable for preview and review',
		"aiMode26": 'General Q&A',
		"aiMode27": 'Comprehensive Q&A and translation training, drawing lessons from one example to improve learning efficiency',
		"aiMode28": 'Xiaobei AI Big Model',
		
		"aiMode29": 'Multimode interaction',
		"aiMode30": 'Multi mode interaction of text, voice, and video',
		"aiMode31": 'codability',
		"aiMode32": 'Code generation, interpretation, error correction, testing',
		"aiMode33": 'Text generation',
		"aiMode34": 'Generation of essays, marketing, press releases, etc',
		"aiMode35": 'General ability',
		"aiMode36": 'Setting and solving questions for all subjects in the entire academic stage',
		"aiMode37": 'language understanding',
		"aiMode38": 'Abstract extraction, grammar check, sentiment analysis',
		
		"aiMode39": 'Knowledge Q&A',
		"aiMode40": 'Knowledge of life, work, medicine, and history',
		"aiMode41": 'logical reasoning',
		"aiMode42": 'Thinking, science, common sense reasoning',
		"aiMode43": 'Multilingual',
		"aiMode44": 'Supports interaction in over 100 languages',
		"aiMode45": 'Xiaobei AI Technology Advantages',
		"aiMode46": '200 million precise corpus',
		"aiMode47": 'Widely used in intelligent office, intelligent education, and intelligent car boxes',
		"aiMode48": 'Global collaborative technology',
		"aiMode49": 'Ultra low latency, safe and stable, intelligent scheduling, stable link',
		"aiMode50": 'Security encryption system',
		"aiMode51": 'Internationally leading four-dimensional encryption system for cloud management, end-to-end, and digital, with full process encryption',
	},
	aiMode:{
		"aiMode1": 'Xiaobei AI Big Model',
		"aiMode2": 'A natural language processing model trained on large-scale neural networks, with various abilities such as natural language understanding, natural language generation, dialogue interaction, knowledge Q&A, and literary creation. Unleash creativity, streamline workflows, and empower intelligent hardware. ',
		"aiMode3": 'Consult',
		"aiMode4": 'Diversification capability',
		"aiMode5": 'AI empowerment, using intelligent hardware as a carrier, providing full stack capabilities and services',
		"aiMode6": 'Multimode interaction',
		"aiMode7": 'Multi mode interaction of text, voice, and video',
		"aiMode8": 'codability',
		"aiMode9": 'Code generation, interpretation, error correction, testing',
		"aiMode10": 'Text generation',
		"aiMode11": 'Generation of essays, marketing, press releases, etc',
		"aiMode12": 'General ability',
		"aiMode13": 'Setting and solving questions for all subjects in the entire academic stage',
		"aiMode14": 'language understanding',
		"aiMode15": 'Abstract extraction, grammar check, sentiment analysis',
		"aiMode16": 'Knowledge Q&A',
		"aiMode17": 'Knowledge of life, work, medicine, and history',
		"aiMode18": 'logical reasoning',
		"aiMode19": 'Thinking, science, common sense reasoning',
		"aiMode20": 'Multilingual',
		"aiMode21": 'Supports interaction in over 100 languages',
		"aiMode22": 'Our strengths',
		
		"aiMode23": 'Anthropomorphic voice interaction',
		"aiMode24": 'Simulate human speech characteristics, intonation, and language style to make human-machine dialogue more natural and friendly, enhancing user experience',
		"aiMode25": 'One click intelligent writing',
		"aiMode26": 'Develop efficient, user-friendly, and intelligent office efficiency tools for essential scenarios. AI one click writing and calibration, making work summary and reporting easy and efficient, an efficiency weapon in the AI era',
		"aiMode27": 'Full scene intelligent assistant',
		"aiMode28": 'An intelligent assistant application that integrates multiple functions and services, providing users with comprehensive support and convenience',
		"aiMode29": 'Quick response',
		"aiMode30": 'Efficient processing',
		"aiMode31": 'Adopting a streaming interface design, the fastest first frame response can reach milliseconds. With efficient algorithms and architecture, it can handle a large number of user needs in an extremely short time',
		"aiMode32": 'Diverse Scenarios',
		"aiMode33": 'Continuous evolution',
		"aiMode34": 'Provide diverse abilities including language comprehension, knowledge Q&A, code writing, logical reasoning, mathematical problem-solving, etc., continuously learning and evolving from massive data and knowledge',
		"aiMode35": 'Flexible application',
		"aiMode36": 'Customized',
		"aiMode37": 'Providing rich parameter settings can achieve personalized model experience. We can provide exclusive model solutions for customized needs at the enterprise level',
		"aiMode38": 'Stable service',
		"aiMode39": 'Safe and reliable',
		"aiMode40": 'Cloud services meet the third level of equal protection standards and adopt a public cloud architecture with billions of traffic levels, combined with multiple means such as data encryption and access control to ensure user privacy and security',
	},
	Product:{
		"Product1": 'Product Center',
		"Product2": 'Office, education, and in car intelligent products, supporting ordering and feature customization',
		"Product3": 'Consult',
	},
	medium:{
		"medium1": 'Media Center',
		"medium2": 'Latest news from Beibo, take a look here',
		"medium3": 'Consult',
	},
	about:{
		"about1": 'Understanding Platinum Platinum',
		"about2": 'Intelligent hardware solution providers for office, education, car boxes, and other areas',
		"about3": 'Consult',
		"about4": 'Company Introduction',
		"about5": 'Qualification certification',
		"about6": '100Over 100 domestic and foreign patents',
		"about7": 'Shenzhen Beibo Intelligent Technology Co., Ltd',
		"about8": 'Founded in 2015, based in Shenzhen, Chinas "smart manufacturing" center, it is a high-tech enterprise with forward-looking vision and innovative spirit.',
		"about9": 'The company has strong software and hardware research and development capabilities, and empowers artificial intelligence hardware by outputting AI skills such as voice technology, image OCR technology, vehicle computer interaction technology, cloud services, etc. It has obtained more than 100 related trademark patents. The landing products include intelligent translation machines, intelligent recording pens, intelligent conference treasure, intelligent scanning pens, intelligent car boxes, etc. In 2022, the annual shipment volume of related products exceeded one million units.',
		"about10": 'Beibo Intelligence focuses on the research and development, production, and sales of artificial intelligence software and hardware. It has multiple strategic partners in Japan, Europe and America, and has established a strong market team and technical support team. We are a leading global provider of intelligent translation device solutions to help humans overcome language barriers.',
	},
	footer:{
		"AboutUs": 'About Us',
		"CollaborativeConsulting": 'Consult',
		"Businesscooperation": 'Business cooperation',
		"phone": 'Contact phone number：+86 13660736734',
		"tel": '0755 27189290',
		"address": '201, Building F, Yuanfen Industrial Zone, Taoyuan Community, Dalang Street, Longhua District, Shenzhen',
		"copyright": 'All rights reserved © Shenzhen Beibo Intelligent Technology Co., Ltd.',
		"icp": 'Yue ICP Bei 2024241358-1',
		"footer2":'Welcome to register',
		"footer3":'Existing account？',
		"footer4":'Please log in',
	},
	login:{
		"login1":'Password login',
		"login2":'SMS login',
		"loginTab3":'Email login',
		"login3":'Email',
		"login4":'Login password',
		"login5":'Mobile phone number',
		"login6":'Please select',
		"login7":'Mobile verification code',
		"login8":'Login',
		"login9":'Forgot password',
		"login10":'register',
		"login11":'By logging in, you agree to',
		"login12":'Service',
		"login13":'Privacy',
		"login14":'get code',
		"login15":'CN',
		"login16":'Please enter your account number',
		"login17":'Please enter password',
		"login18":'Login successful!!',
		"login19":'Please enter the verification code!',
		"login20":'Sent successfully!',
		"login21":'and',
		
		"login22":'Recording Cloud',
		"login23":'My Writing',
		"login24":'My conversation',
		"login25":'Account security',
		"login26":'Login out',
		
		"login27":'Email password retrieval',
		"login28":'Mailbox',
		"login29":'Email verification code',
		"login30":'Set login password (6-16 characters)',
		"login31":'Enter login password again',
		"login32":'Submit',
		"login33":'Return to login',
		"login34":'Modified successfully!',
	},
	register:{
		"register1":'Phone',
		"register2":'Please enter your phone number',
		"register3":'Please select',
		"register4":'Mobile code',
		"register5":'Password',
		"register6":'Please enter the verification code',
		"register7":'6-16 characters',
		"register8":'Password confirm',
		"register9":'Mailbox',
		"register10":'Please enter your email address',
		"register11":'Email code',
		"register12":'Please enter the verification code',
		"register13":'I have read and agree',
		"register14":'Please read and check the agree agreement',
		"register15":'Phone number format error/phone number registered',
		"register16":'Mobile verification code error',
		"register17":'Please enter email/email format error',
		"register18":'Email format error',
		"register19":'Email verification code error',
		"register20":'Please set the password to 6-16 characters',
		"register21":'Password format error',
		"register22":'The passwords entered twice are inconsistent',
		"register23":'Please enter password',
		"register24":'registered successfully',
		"register25":'Verification code error',
	},
	recorder:{
		"recorder1":'Recording Cloud',
		"recorder2":'Cloud space capacity',
		"recorder3":'Recent Files',
		"recorder4":'name',
		"recorder5":'Audio duration',
		"recorder6":'file size',
		"recorder7":'Creation time',
		"recorder8":'operation',
		"recorder9":'Cloud Space Description',
		"recorder10":'audio',
		"recorder11":'OCR files',
		"recorder12":'1.What is cloud space used for?',
		"recorder13":'After recording on the recording pen, you can upload audio and transcribe files to the cloud space. Even if the recording pen is not around, you can still enter the cloud space to view the audio.',
		"recorder14":'2.How to upload cloud space?',
		"recorder15":'Go to "My Files" on the recording pen, select the target file, long press the option that appears, and click "Upload" to upload the file to the cloud space.',
		"recorder16":'3.How to view/manage cloud space files?',
		"recorder17":'Log in to the official website of Beibo and select "My - Recording Pen Cloud Disk" to view the uploaded recording pen files. You can select the target file for downloading, deleting, and other operations. You can clean up the storage in cloud space by deleting cloud space files!',
		
		"recorder18":'Untransferred',
		"recorder19":'View Summary',
		"recorder20":'Speaker',
		"recorder21":'Editors Speaker Name',
		"recorder22":'confirm',
		"recorder23":'download',
		"recorder24":'Download content:',
		"recorder25":'Download settings:',
		"recorder26":'Show Speaker',
		"recorder27":'cancel',
		"recorder28":'Link sharing',
		"recorder29":'Anyone who obtains the link can access it, only reading is supported, and the link is valid for 7 days',
		"recorder30":'Copy Link',
		"recorder31":'Scan for quick access',
		"recorder32":'Intelligent organization',
		"recorder33":'Distinguishing speakers',
		"recorder34":'Intelligent differentiation of transcribed text based on the speakers role',
		"recorder35":'Intelligent spoken language regulation',
		"recorder36":'Automatically filter modal particles, repeating words, and pausing words',
		"recorder37":'Non human voice filtering',
		"recorder38":'Automatically skip audio without human voice or noise during playback',
		"recorder39":'Please select',
		"recorder40":'Document',
		"recorder41":'audio frequency',
		"recorder42":'Document+Audio',
		"recorder43":'Tips',
		"recorder44":'This operation will delete the file. Do you want to continue?',
		"recorder45":'Confirm',
		"recorder46":'Cancel',
		"recorder47":'Deleted successfully!',
		"recorder48":'Undeleted',
		"recorder49":'No tags yet',
		"recorder50":'Saving…',
	},
	personal:{
		"personal1":'Account settings',
		"personal2":'personal information',
		"personal3":'Avatar',
		"personal4":'Upload files',
		"personal5":'Supports JPG, JPEG, GIF, PNG styles, and images up to 2M in size',
		"personal6":'nickname',
		"personal7":'Please enter your nickname',
		"personal8":'Gender',
		"personal9":'gentleman',
		"personal10":'lady',
		"personal11":'secrecy',
		
		"personal12":'birthday',
		"personal13":'Please select your birthday',
		"personal14":'occupation',
		"personal15":'Please choose a profession',
		"personal16":'Please enter occupation (not mandatory)',
		"personal17":'Country',
		"personal18":'Please select a country',
		"personal19":'preserve',
		"personal20":'personal information',
		"personal21":'Account security',
		"personal22":'Only supports images up to 2M in size',
		"personal23":'Upload successful!',
		"personal24":'Modified successfully!',
		
		"personal25":'Account security',
		"personal26":'password',
		"personal27":'modify',
		"personal28":'E-mail',
		"personal29":'replace',
		"personal30":'Account cancellation',
		"personal32":'Are you sure to cancel your account?',
		"personal33":'After logging out, all content in your account will be cleared. Please operate with caution.',
		"personal34":'cancel',
		"personal35":'confirm',
		"personal36":'Identity verification',
		"personal37":'To confirm that it was your own operation, please complete the identity verification through the email verification code',
		"personal38":'mailbox',
		"personal39":'code',
		"personal40":'Please enter the code',
		"personal41":'Next step',
		"personal42":'Set up a new email',
		"personal43":'mailbox',
		"personal44":'Please enter a new email address',
		"personal45":'code',
		"personal46":'Please enter the code',
		"personal47":'confirm',
		"personal48":'Set a new login password',
		"personal49":'password',
		"personal50":'Please enter a new password',
		"personal51":'Confirm password',
		"personal52":'Please enter the new password again',
		"personal53":'confirm',
		"personal54":'get code',
		
		"personal55":'Email format error',
		"personal56":'Replacement successful!',
		"personal57":'Please set the password to 6-16 characters',
		"personal58":'The passwords entered twice are inconsistent',
		"personal59":'Modified successfully!',
		"personal60":'Logout successful',
	}
}