import { getRequest , postRequest,sendConcurrentRequests } from "../common/request";
import { baseUrl } from "../common/baseUrl";

//首页Banner
export const getBanner = (params) => {
	return postRequest(baseUrl + '/web/banner', params)
}

//资讯列表
export const getNewsList = (params) => {
	return postRequest(baseUrl + '/web/news/list', params)
}

//资讯详情
export const getNewsView = (params) => {
	return postRequest(baseUrl + '/web/news/view', params)
}

//合作伙伴
export const getPartner = (params) => {
	return postRequest(baseUrl + '/web/partner', params)
}

//产品分类
export const getProductCategory = (params) => {
	return postRequest(baseUrl + '/web/product/category', params)
}
//产品列表
export const getProduct = (params) => {
	return postRequest(baseUrl + '/web/product/list', params)
}
//方案分类
export const getSolutionCategory = (params) => {
	return postRequest(baseUrl + '/web/solution/category', params)
}

//方案列表
export const getSolutionCategorylist = (params) => {
	return postRequest(baseUrl + '/web/solution/list', params)
}

//方案列表
export const getSolutionCategoryview = (params) => {
	return postRequest(baseUrl + '/web/solution/view', params)
}

//反馈建议
export const getFeedback = (params) => {
	return postRequest(baseUrl + '/web/feedback', params)
}


export const getSendMSM = (params) => {
	return postRequest(baseUrl + '/api/captcha', params)
}

export const getRegister = (params) => {
	return postRequest(baseUrl + '/api/register', params)
}

export const getLogin = (params) => {
	return postRequest(baseUrl + '/api/login/password', params)
}

export const getLoginCode = (params) => {
	return postRequest(baseUrl + '/api/login/code', params)
}

export const getUserInfo = (params) => {
	return postRequest(baseUrl + '/api/user/profile', params)
}

export const getforgotPW = (params) => {
	return postRequest(baseUrl + '/api/forgot/password', params)
}


export const getCountry = (params) => {
	return postRequest(baseUrl + '/api/country', params)
}

export const getIndustry = (params) => {
	return postRequest(baseUrl + '/api/industry', params)
}

export const getSts = (params) => {
	return postRequest(baseUrl + '/api/sts', params)
}

export const getUserUpdate = (params) => {
	return postRequest(baseUrl + '/api/user/update', params)
}

export const getAccountDel = (params) => {
	return postRequest(baseUrl + '/api/account/delete', params)
}
//发送验证码（登录）
export const getCaptcha2 = (params) => {
	return postRequest(baseUrl + '/api/captcha2', params)
}

//发送验证码
export const getCaptcha = (params) => {
	return postRequest(baseUrl + '/api/captcha', params)
}

//账户授权
export const getAccountAuth = (params) => {
	return postRequest(baseUrl + '/api/account/auth', params)
}

//账户绑定
export const getAccountBind = (params) => {
	return postRequest(baseUrl + '/api/account/bind', params)
}

//账户密码修改
export const getAccountPW = (params) => {
	return postRequest(baseUrl + '/api/account/password', params)
}

//音频列表
export const getAudioList = (params) => {
	return postRequest(baseUrl + '/api/audio/list', params)
}

//音频详情
export const getAudioDetail = (params) => {
	return postRequest(baseUrl + '/api/audio/detail', params)
}

//音频文本
export const getAudioTextList = (params) => {
	return postRequest(baseUrl + '/api/audio/text/list2', params)
}

//音频文本
export const getAudioTextData = (params) => {
	return postRequest(baseUrl + '/api/audio/text/list', params)
}

//音频修改角色
export const getAudioRole = (params) => {
	return postRequest(baseUrl + '/api/audio/role', params)
}

//音频修改文本
export const getAudioTextUpdate = (params) => {
	return postRequest(baseUrl + '/api/audio/text/update', params)
}

//音频name修改
export const getAudioUpdate = (params) => {
	return postRequest(baseUrl + '/api/audio/update', params)
}

//音频文件创建
export const getAudioDocxCreate = (params) => {
	return postRequest(baseUrl + '/api/audio/docx/create', params)
}

//音频文件创建
export const getAudioShare = (params) => {
	return postRequest(baseUrl + '/api/audio/share', params)
}


//音频更新
export const getAudioFileUpdate = (params) => {
	return postRequest(baseUrl + '/api/audio/file/update', params)
}


//音频文本
export const getAudioTextList2 = (id,params) => {
	return sendConcurrentRequests(baseUrl + '/api/audio/text/list',id, params)
}