<template>
	<div>
		<!-- ======= Hero Section  d-flex ======= -->
		<section id="hero" class="align-items-center">
			<div id="carouselExampleIndicators" class="carousel slide carousel-fade carousel-dark"
				data-bs-ride="carousel">
				<el-carousel height="417px" ref="carousel" :interval="3000" @change="changebanner($event)" indicator-position="none">
					<el-carousel-item v-for="(item,index) in bannerList" :key="index">
						<div @click="toUrl(item)">
							<img :src="item.res_url | hasHttp" style="height:417px;" class="d-block w-100 cursor-1"
								alt="...">
							<div class="el-bg-view container">
								<h5 class="hero-desc-tilte">{{item.title}}</h5>
								<p class="hero-desc-text" style="width: 55%;color: #DCDCDC;">{{item.tips}}
								</p>
							</div>
						</div>
					</el-carousel-item>
					 <ul class="custom-indicators container" v-if="bannerList.length>1">
						<div class="custom-indicators-1" v-for="(item,index) in bannerList" :key="index" @click="changebanner2(index)">
							<el-progress stroke-linecap="round" :percentage="(index==carouselIndex)?Number(percentage):0"
								:show-text="false" :color="customColor"></el-progress>
						</div>
					</ul>
				</el-carousel>
			</div>
		</section>
		<main id="main">
			<!-- 核心能力 -->
			<section id="core-services" class="core-services">
				<!--  data-aos="fade-up" -->
				<div class="container">
					<div class="section-title">
						<h3>{{$t('home.home1')}}</h3>
						<p>{{$t('home.home14')}}</p>
					</div>
					<!--  justify-content-between -->
					<div class="row row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5"
						style="margin-top: 60px;">
						<div class="col-6">
							<div class="core-box-1">
								<img src="../assets/img/icon/i1.png" style="width: 64px;height: 64px;" alt="">
								<p class="core-name">{{$t('home.home15')}}</p>
							</div>
						</div>
						<div class="col-6">
							<div class="core-box-1">
								<img src="../assets/img/icon/i2.png" style="width: 64px;height: 64px;" alt="">
								<p class="core-name">{{$t('home.home16')}}</p>
							</div>
						</div>
						<div class="col-6">
							<div class="core-box-1">
								<img src="../assets/img/icon/i3.png" style="width: 64px;height: 64px;" alt="">
								<p class="core-name">{{$t('home.home17')}}</p>
							</div>
						</div>
						<div class="col-6">
							<div class="core-box-1">
								<img src="../assets/img/icon/i4.png" style="width: 64px;height: 64px;" alt="">
								<p class="core-name">{{$t('home.home18')}}</p>
							</div>
						</div>
						<div class="col-6">
							<div class="core-box-1">
								<img src="../assets/img/icon/i5.png" style="width: 64px;height: 64px;" alt="">
								<p class="core-name">{{$t('home.home19')}}</p>
							</div>
						</div>
						<div class="col-6">
							<div class="core-box-1">
								<img src="../assets/img/icon/i6.png" style="width: 64px;height: 64px;" alt="">
								<p class="core-name">{{$t('home.home20')}}</p>
							</div>
						</div>
						<div class="col-6">
							<div class="core-box-1">
								<img src="../assets/img/icon/i7.png" style="width: 64px;height: 64px;" alt="">
								<p class="core-name">{{$t('home.home21')}}</p>
							</div>
						</div>
						<div class="col-6">
							<div class="core-box-1">
								<img src="../assets/img/icon/i8.png" style="width: 64px;height: 64px;" alt="">
								<p class="core-name">{{$t('home.home22')}}</p>
							</div>
						</div>
						<div class="col-6">
							<div class="core-box-1">
								<img src="../assets/img/icon/i9.png" style="width: 64px;height: 64px;" alt="">
								<p class="core-name">{{$t('home.home23')}}</p>
							</div>
						</div>
						<div class="col-6">
							<div class="core-box-1">
								<img src="../assets/img/icon/i10.png" style="width: 64px;height: 64px;" alt="">
								<p class="core-name">{{$t('home.home24')}}</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- 技术生产力 -->
			<section id="productivity-services" class="productivity-services">
				<div class="row" style="margin: 0;padding: 0;">
					<img style="margin: 0;padding: 0;" src="../assets/img/header/banner3.png" class="d-block w-100"
						alt="..." />
				</div>
				<div class="container" style="position: relative;">
					<div class="row">
						<div class="productivity-services-box">
							<div class="row row-cols-sm-3 row-cols-md-3 row-cols-lg-3 row-cols-xl-3 d-flex align-items">
								<div class="productivity-services-box-1" style="display: flex;justify-content: flex-start;flex-direction: column;">
									<div class="bx-color-1">
										<span class="number">60%</span><span
											class="number-1">{{$t('home.home29')}}</span>
									</div>
									<div class="bx-color-1" style="margin-top: 18px;">
										<span class="number-name">{{$t('home.home26')}}</span>
									</div>
								</div>
								<div class="productivity-services-box-1"  style="display: flex;align-items: center;flex-direction: column;">
									<div class="bx-color-1">
										<span class="number">¥1.2</span><span
											class="number-1">{{$t('home.home30')}}</span>
									</div>
									<div class="bx-color-1" style="margin-top: 18px;">
										<span class="number-name">{{$t('home.home27')}}</span>
									</div>
								</div>
								<div class="productivity-services-box-1"  style="display: flex;align-items: flex-end;flex-direction: column;">
									<div class="bx-color-1">
										<span class="number">100+</span><span
											class="number-1">{{$t('home.home31')}}</span>
									</div>
									<div class="bx-color-1" style="margin-top: 18px;">
										<span class="number-name">{{$t('home.home28')}}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section id="productivity2-services" class="productivity2-services">
				<div class="container" style="position: relative;">
					<div class="section-title">
						<h3>{{$t('home.home32')}}</h3>
						<p style="display: flex;align-items: center;justify-content: center;text-align: left;">{{$t('home.home33')}}</p>
					</div>
					<div class="pro-list-box">
						<div class="pro-list-box-1 cursor-1" :class="tabIndex==index?'pro-list-box-1-active':''"
							@click="changeProType(index)" v-for="(item,index) in solutionCategory" :key="index" @mousemove="changeProType(index)">{{item.name}}</div>
						<!-- <div class="pro-list-box-1 cursor-1" :class="tabIndex==1?'pro-list-box-1-active':''"
							@click="changeProType(1)">{{$t('home.home55')}}</div>
						<div class="pro-list-box-1 cursor-1" :class="tabIndex==2?'pro-list-box-1-active':''"
							@click="changeProType(2)">{{$t('home.home56')}}</div> -->
					</div>
				</div>
				<div class="row" style="margin: 0;padding: 0;position: relative;">
					<img v-show="tabIndex==0?true:false" style="margin: 0;padding: 0;" src="../assets/img/header/b1.png"
						class="w-100" alt="..." />
					<img v-show="tabIndex==1?true:false" style="margin: 0;padding: 0;" src="../assets/img/header/b2.png"
						class="w-100" alt="..." />
					<img v-show="tabIndex==2?true:false" style="margin: 0;padding: 0;" src="../assets/img/header/b3.png"
						class="w-100" alt="..." />
				</div>
				<div class="container productivity2-box">
					<!-- style="width: 100%;" -->
					<div class="row row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-2 justify-content-between" style="flex:1;margin: 0;">
						<div class="productivity2-box-left" :class="isPc?'productivity2-box-right-list-flex-left':''">
							<div class="lable">{{$t('home.home34')}}</div>
							<div class="productivity2-box-right-list">
								<div class="left-1" v-if="item.name" :class="tabIndex2==index?'left-1-active':''"
									v-for="(item,index) in getSolutionCategoryDataList" :key="index"
									 @mousemove="proTypeMove(index)" @mouseleave="proTypeLeave(index)" @click="changeProType2(item,index)">
									{{item.name}}
									<!-- <i class="el-icon-arrow-right"></i> -->
								</div>
							</div>
						</div>
						<div class="productivity2-box-right" :class="isPc?'productivity2-box-right-list-flex-right':''">
							<div class="lable">{{$t('home.home35')}}</div>
							<div class="productivity2-box-right-list" v-if="tabIndex==0">
								<div class="right-1"><img src="../assets/img/icon/dian.png" />{{$t('home.home57')}}</div>
								<div class="right-1"><img src="../assets/img/icon/dian.png" />{{$t('home.home58')}}</div>
								<div class="right-1"><img src="../assets/img/icon/dian.png" />{{$t('home.home59')}}</div>
								<div class="right-1"><img src="../assets/img/icon/dian.png" />{{$t('home.home60')}}</div>
								<div class="right-1"><img src="../assets/img/icon/dian.png" />{{$t('home.home61')}}</div>
								<div class="right-1"><img src="../assets/img/icon/dian.png" />{{$t('home.home62')}}</div>
								<div class="right-1"><img src="../assets/img/icon/dian.png" />{{$t('home.home63')}}</div>
								<div class="right-1"><img src="../assets/img/icon/dian.png" />{{$t('home.home64')}}</div>
							</div>
							<div class="productivity2-box-right-list" v-if="tabIndex==1">
								<div class="right-1"><img src="../assets/img/icon/dian.png" />{{$t('home.home571')}}</div>
								<div class="right-1"><img src="../assets/img/icon/dian.png" />{{$t('home.home581')}}</div>
								<div class="right-1"><img src="../assets/img/icon/dian.png" />{{$t('home.home591')}}</div>
								<div class="right-1"><img src="../assets/img/icon/dian.png" />{{$t('home.home601')}}</div>
								<div class="right-1"><img src="../assets/img/icon/dian.png" />{{$t('home.home611')}}</div>
								<div class="right-1"><img src="../assets/img/icon/dian.png" />{{$t('home.home621')}}</div>
								<div class="right-1"><img src="../assets/img/icon/dian.png" />{{$t('home.home631')}}</div>
								<div class="right-1"><img src="../assets/img/icon/dian.png" />{{$t('home.home641')}}</div>
							</div>
							<div class="productivity2-box-right-list" v-if="tabIndex==2">
								<div class="right-1"><img src="../assets/img/icon/dian.png" />{{$t('home.home572')}}</div>
								<div class="right-1"><img src="../assets/img/icon/dian.png" />{{$t('home.home582')}}</div>
								<div class="right-1"><img src="../assets/img/icon/dian.png" />{{$t('home.home592')}}</div>
								<div class="right-1"><img src="../assets/img/icon/dian.png" />{{$t('home.home602')}}</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- 全栈服务 -->
			<section id="stack-services" class="stack-services">
				<div class="container stack-services-bg" style="padding-bottom: 60px;">
					<div class="section-title" style="padding-top: 182px;padding-bottom: 60px;">
						<h3>{{$t('home.home36')}}</h3>
						<p>{{$t('home.home37')}}</p>
					</div>
					<div class="row row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-4 justify-content-between">
						<div class="col-12">
							<div class="stack-box-view cursor-1">
								<img class="stack-img" src="../assets/img/icon/j1.png" style="height: 64px;" alt="">
								<div class="stack-box-1-list">
									<p class="stack-title">{{$t('home.home38')}}</p>
									<p class="subtitle">{{$t('home.home42')}}</p>
								</div>
							</div>
						</div>
						<div class="col-12">
							<div class="stack-box-view cursor-1">
								<img class="stack-img" src="../assets/img/icon/j2.png" style="height: 64px;" alt="">
								<div class="stack-box-1-list">
									<p class="stack-title">{{$t('home.home39')}}</p>
									<p class="subtitle">{{$t('home.home43')}}</p>
								</div>
							</div>
						</div>
						<div class="col-12">
							<div class="stack-box-view cursor-1">
								<img class="stack-img" src="../assets/img/icon/j3.png" style="height: 64px;" alt="">
								<div class="stack-box-1-list">
									<p class="stack-title">{{$t('home.home40')}}</p>
									<p class="subtitle">{{$t('home.home44')}}</p>
								</div>
							</div>
						</div>
						<div class="col-12">
							<div class="stack-box-view cursor-1">
								<img class="stack-img" src="../assets/img/icon/j4.png" style="height: 64px;" alt="">
								<div class="stack-box-1-list">
									<p class="stack-title">{{$t('home.home41')}}</p>
									<p class="subtitle">{{$t('home.home45')}}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- 新闻咨询 -->
			<section id="new-services" class="new-services">
				<div class="container">
					<div class="section-title" style="padding-bottom: 60px;">
						<h3>{{$t('home.home48')}}</h3>
						<p>{{$t('home.home49')}}</p>
					</div>
					<!--  justify-content-between -->
					<div class="row row-cols-sm-1 row-cols-md-1 row-cols-lg-1 row-cols-xl-3">
						<div class="col-12" v-for="(item,index) in getNewsList" :key="index" @click="goNewsDetails(item)">
							<div class="core-box-view cursor-1">
								<div class="core-box-1" style="width: 100%;">
									<img :src="item.picUrl | hasHttp" style="width: 100%;height: 260px;" alt="">
									<div class="core-box-1-list">
										<p class="title wz-text">{{item.title}}</p>
										<p class="time">
											<img src="../assets/img/icon/time.png" />
											<span>{{item.createTime | dateFormat("YYYY-MM-DD") }}</span>
										</p>
										<p class="look">
											{{$t('home.home50')}}<img src="../assets/img/icon/r1.png" />
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- 资质认证 -->
			<section id="aptitude-services" class="aptitude-services">
				<div class="container2">
					<div class="section-title" style="padding-bottom: 101px;">
						<div class="aptitude-box">
							<div class="aptitude-icon">
								<img src="../assets/img/icon/z1.png" />
							</div>
							<div>
								<h3>{{$t('home.home46')}}</h3>
								<p style="width: 100%;text-align: center;">{{$t('home.home47')}}</p>
							</div>
							<div class="aptitude-icon">
								<img src="../assets/img/icon/z2.png" />
							</div>
						</div>
					</div>
					<div class="aptitude-box-2">
						<div class="aptitude-box-3 container"
							style="display: flex;align-items: center;justify-content: center;">
							<img class="aptitude-bg" src="../assets/img/icon/zhengshu-bg.png" />
							<div class="aptitude-row-list  row row-cols-sm-12 row-cols-md-4 row-cols-lg-4 row-cols-xl-4">
								<div class="col-2-222" style="position: relative;">
									<img class="aptitude-img"  :class="isPc?'aptitude-img-z':''"   src="../assets/img/icon/zhengshu.png" />
									<img v-if="isPc"   class="aptitude-img-bg" src="../assets/img/icon/zhenshu-bg.png" />
								</div>
								<div class="col-2-222" style="position: relative;">
									<img class="aptitude-img"  :class="isPc?'aptitude-img-z':''"   src="../assets/img/icon/zhengshu.png" />
									<img v-if="isPc"   class="aptitude-img-bg" src="../assets/img/icon/zhenshu-bg.png" />
								</div>
								<div class="col-2-222" style="position: relative;">
									<img class="aptitude-img"  :class="isPc?'aptitude-img-z':''"   src="../assets/img/icon/zhengshu.png" />
									<img v-if="isPc"   class="aptitude-img-bg" src="../assets/img/icon/zhenshu-bg.png" />
								</div>
								<div class="col-2-222" style="position: relative;">
									<img class="aptitude-img"  :class="isPc?'aptitude-img-z':''"   src="../assets/img/icon/zhengshu.png" />
									<img v-if="isPc"   class="aptitude-img-bg" src="../assets/img/icon/zhenshu-bg.png" />
								</div>
							</div>
						</div>
						<div class="aptitude-box-3 container"
							style="display: flex;align-items: center;justify-content: center;margin-top: 270px;padding-bottom: 118px;">
							<img class="aptitude-bg" src="../assets/img/icon/zhengshu-bg.png" />
							<div
								class="aptitude-row-list  row row-cols-sm-12 row-cols-md-4 row-cols-lg-4 row-cols-xl-4">
								<div class="col-2-222" style="position: relative;">
									<img class="aptitude-img"  :class="isPc?'aptitude-img-z':''"   src="../assets/img/icon/zhengshu.png" />
									<img v-if="isPc"   class="aptitude-img-bg" src="../assets/img/icon/zhenshu-bg.png" />
								</div>
								<div class="col-2-222" style="position: relative;">
									<img class="aptitude-img"  :class="isPc?'aptitude-img-z':''"   src="../assets/img/icon/zhengshu.png" />
									<img v-if="isPc"   class="aptitude-img-bg" src="../assets/img/icon/zhenshu-bg.png" />
								</div>
								<div class="col-2-222" style="position: relative;">
									<img class="aptitude-img"  :class="isPc?'aptitude-img-z':''"   src="../assets/img/icon/zhengshu.png" />
									<img v-if="isPc"   class="aptitude-img-bg" src="../assets/img/icon/zhenshu-bg.png" />
								</div>
								<div class="col-2-222" style="position: relative;">
									<img class="aptitude-img"  :class="isPc?'aptitude-img-z':''"  src="../assets/img/icon/zhengshu.png" />
									<img v-if="isPc"   class="aptitude-img-bg" src="../assets/img/icon/zhenshu-bg.png" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- 合作伙伴 -->
			<section id="cooperate-services" class="cooperate-services">
				<div class="container">
					<div class="section-title">
						<h3>{{$t('home.home51')}}</h3>
						<p>{{$t('home.home52')}}</p>
					</div>
					<div class="row row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 align-self-end">
						<div class="col-6" v-for="(item,index) in partnerList" :key="index">
							<div class="core-box-1">
								<img :src="item.pic_url | hasHttp" style="max-width: 225px;height: 114px;" alt="">
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- 合作咨询 -->
			<section id="consult-services" class="consult-services">
				<div class="container">
					<div class="consult-but d-flex">
						<a href="/consult" class="consult-but-started scrollto">{{$t('home.home53')}}</a>
					</div>
				</div>
			</section>
		</main>
	</div>
</template>

<script>
	// import Purecounter from '@srexi/purecounterjs';
	// import Swiper from 'swiper/swiper-bundle.min.js';
	
	import { getBanner , getNewsList , getPartner,
	 getSolutionCategory,getSolutionCategorylist} from "../../common/api";
	export default {
		name: 'Home',
		components: {

		},
		data() {
			return {
				lang:'zh',
				timer: null,
				percentage: 0,
				customColor: '#10B1A7',
				activeIndex: 1,
				tabIndex: 0,
				tabIndex2:-1,
				carouselIndex:0,
				oneWidth: '100%',
				intersectionOptions: {
					root: document.querySelector(".skills-content"),
					rootMargin: '0px 0px 0px 0px',
					threshold: [0.25, 0.75] // [0.25, 0.75] if you want a 25% offset!
				},
				bannerList:[],
				getNewsList:[],
				partnerList:[],
				solutionCategory:[],
				getSolutionCategoryDataList:[],
				isPc:false,
			}
		},
		created() {

		},
		//生命周期 - 挂载完成（可以访问 DOM 元素）
		mounted() {
			let lang=localStorage.getItem('lang') || 'zh';
			this.lang=lang;
			
			this.intervalId = setInterval(() => {
				if(this.percentage<100){
					this.percentage += 10;
				}
			}, 260);
			
			if(window.innerWidth<991){
				this.isPc=false;
			}else{
				this.isPc=true;
			}
			window.addEventListener('resize', this.handleResize);
			
			this.getBannerData();
			this.getNewsListData();
			this.getPartnerData();
			this.getSolutionCategoryData();
		},
		destroyed(){
			clearInterval(this.intervalId);
		},
		methods: {
			handleResize() {
				let windowWidth = window.innerWidth;
				let windowHeight = window.innerHeight;
				if(windowWidth<991){
					this.isPc=false;
				}else{
					this.isPc=true;
				}
			},
			getBannerData(){
				const params={}
				getBanner(params).then((res) => {
					if(res.code==0){
						this.bannerList=res.data;
					}
				})
			},
			getNewsListData(){
				const params={
					"page_num": 1,
					"page_size": 3
				}
				getNewsList(params).then((res) => {
					if(res.code==0){
						this.getNewsList=res.data.data_list;
					}
				})
			},
			getPartnerData(){
				const params={}
				getPartner(params).then((res) => {
					if(res.code==0){
						this.partnerList=res.data;
					}
				})
			},
			getSolutionCategoryData(){
				const params={}
				getSolutionCategory(params).then((res) => {
					if(res.code==0){
						this.solutionCategory=res.data;
						this.getSolutionCategoryListData();
					}else{
						this.$message.error(res.msg);
					}
				})
			},
			getSolutionCategoryListData(){
				const params={
					"cid": this.solutionCategory[this.tabIndex].id,
				}
				getSolutionCategorylist(params).then((res) => {
					if(res.code==0){
						this.getSolutionCategoryDataList=res.data;
					}else{
						this.$message.error(res.msg);
					}
				})
			},
			toUrl(item){
				if(item.go_url){
					 if(item.go_url.includes('http')){
						//window.location.href = item.go_url;
						window.open(item.go_url,'_blank')
					 }else{
						this.$router.push({
							path: item.go_url,
							query: {},
						})
					}
				}
			},
			goNewsDetails(item){
				this.$router.push({
					path: "/mediumDetails",
					query: { id: item.id },
				})
			},
			changebanner(index){
				this.carouselIndex=index;
				this.percentage=0;
			},
			changebanner2(index){
				this.$refs.carousel.setActiveItem(index)
				this.carouselIndex=index;
				this.percentage=0;
			},
			changeProType(index) {
				this.tabIndex = index;
				this.getSolutionCategoryListData();
			},
			changeProType2(item,index) {
				this.tabIndex2 = index;
				this.$router.push({
					path: "/programmeDetails",
					query: { id: item.sol_id},
				})
				//this.getSolutionCategoryListData();
			},
			proTypeMove(index){
				this.tabIndex2 = index;
			},
			proTypeLeave(){
				this.tabIndex2 =-1;
			}
		},
	}
</script>
<style lang="scss" scoped>
	.productivity-services .core-box-1 {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.productivity-services .core-box-1 p {
		font-family: Source Han Sans CN, Source Han Sans CN;
		font-weight: 400;
		font-size: 16px;
		color: #333333;
		line-height: 19px;
		text-align: center;
		font-style: normal;
		text-transform: none;

		margin-top: 24px;
	}

	.core-services {
		background-image: url('../assets/img/header/mian1.png');
		background-size: cover;
	}

	.core-services .row .col-6 {
		padding-bottom: 80px;
	}

	.core-services .section-title h3 {
		background: none !important;
	}

	.core-services .section-title p {
		font-family: Source Han Sans CN, Source Han Sans CN;
		font-weight: 400;
		font-size: 16px;
		color: #999999;
		line-height: 19px;
		text-align: center;
		font-style: normal;
		text-transform: none;
	}

	.stack-services {
		/* padding: ; */
		background-color: #F8F8F8;

	}

	.stack-services-bg {
		background-image: url('../assets/img/header/banner8-bg.png');
		background-size: cover;
	}

	::v-deep .el-progress {
		width: 112px;
		height: 3px;
		background: rgba(255, 255, 255, 0.3);
		border-radius: 0px 0px 0px 0px;
		margin-right: 10px;
		cursor: pointer;
	}

	::v-deep .el-progress-bar__outer {
		width: 112px !important;
		height: 3px !important;
	}

	/* ::v-deep .el-progress-bar__inner{
		width: 112px !important;
	} */

	/* .carousel-dark .carousel-indicators{
		width: 112px;
		height: 3px;
		background: rgba(255,255,255,0.3);
	} */
	/* .carousel-indicators{
		background: rgba(16, 177, 167, 1);
	} */
	.carousel-dark .carousel-indicators [data-bs-target] {
		width: 112px;
		background: #FFF !important;
	}

	.carousel-dark .carousel-indicators .active {
		background: rgba(16, 177, 167, 1) !important;
	}

	.carousel-indicators button {
		height: 3px;
		border-top: 0 solid transparent;
		border-bottom: 0 solid transparent;
		margin-bottom: 4rem;
	}

	.pro-list-box {
		display: flex;
		align-items: center;
		justify-content: space-evenly;

		.pro-list-box-1 {
			font-family: Source Han Sans CN, Source Han Sans CN;
			font-weight: 400;
			font-size: 20px;
			color: #333333;
			line-height: 23px;
			text-align: center;
			font-style: normal;
			text-transform: none;
			margin-bottom: 40px;

			display: flex;
			align-items: center;
			justify-content: center;
		}

		.pro-list-box-1-active {
			color: #10B1A7;
			position: relative;
		}

		.pro-list-box-1-active::before {
			content: "";
			position: absolute;
			left: 13%;
			right: 13%;
			height: 4px;
			bottom: -10px;
			background-color: #10B1A7;
			transition: all 0.3s ease-in-out 0s;
		}
	}

	::v-deep .el-carousel__arrow--left {
		display: none !important;
	}

	::v-deep .el-carousel__arrow--right {
		display: none !important;
	}

	::v-deep .el-carousel__indicators .el-carousel__button {
		width: 112px;
	}

	::v-deep .el-carousel__indicators .is-active .el-carousel__button {

		background: #10B1A7 !important;
	}

	::v-deep .el-carousel__indicators--horizontal {
		bottom: 35px !important;
		left: 25.5% !important;
		transform: translateX(-50%);
	}

	.el-bg-view {
		position: absolute;
		left: 0;
		right: 0;
		top: 75px;
		z-index: 9999;
		color: #FFF;
		cursor: pointer;
		.hero-desc-tilte {
			font-size: 3rem !important;
			line-height: 4rem;
			font-family: "Open Sans", sans-serif !important;
			font-weight: 500 !important;
			color: #FFFFFF;
			line-height: 56px;
			text-align: left;
			font-style: normal;
			text-transform: none;
		}

		.hero-desc-text {
			font-family: Source Han Sans CN, Source Han Sans CN;
			font-weight: 400;
			font-size: 16px;
			color: #DCDCDC;
			line-height: 19px;
			text-align: left;
			font-style: normal;
			text-transform: none;
			margin-top: 20px;
		}
	}
	
	.custom-indicators{
		list-style: none;
		position: absolute;
		bottom: 45px;
		left: 0;
		right: 0;
		z-index: 97;
		
		display: flex;
		align-items: center;
		
		.custom-indicators-1{
			padding: 20px 0;
			cursor: pointer;
		}
	}
	
	@media (max-width: 991px) {
		.new-services{
			padding-bottom: 30px;
		}
		.el-bg-view .hero-desc-tilte{
			font-size: 24px !important;
			line-height: 28px !important;
		}
		.el-bg-view .hero-desc-text{
			max-width: 100% !important;
			width: 100% !important;
			font-size: 14px !important;
			line-height: 15px !important;
		}
		.custom-indicators{
			bottom: 20px;
		}
		::v-deep .el-carousel__container{
			height: 240px !important;
		}
		::v-deep .el-carousel__item img{
			height: 240px !important;
		}
		.stack-box-view{
			width: 100% !important;
			margin-bottom: 20px;
		}
		.section-title h3{
			font-size: 28px;
		}
		.cooperate-services .core-box-1 img{
			max-width:170px !important;
		}
		.productivity-services-box{
			padding: 0 ;
			position: inherit !important;
			.row{
				margin-right: 0 !important;
				margin-left: 0 !important;
			}
			.row .bx-color-1{
				width: 100%;
			}
			.row .productivity-services-box-1{
				flex:1;
			}
			.bx-color-1{
				text-align: center;
				.number{
					font-size: 20px !important;
				}
				.number-1{
					font-size: 15px !important;
				}
			}
		}
		.productivity2-services{
			padding-top: 60px;
			.productivity2-box{
				position: inherit;
				bottom:0;
				padding: 10px;
				.left-1{
					margin-right: 0;
				}
				.productivity2-box-right{
					padding-left: 10px !important;
					.productivity2-box-right-list{
						display: flex;
						justify-content: space-between;
						.right-1{
							margin-right: 0;
						}
					}
				}
			}
			.row{
				img{
					height: 180px;
				}
			}
		}
		
		.stack-services{
			.section-title{
				padding-top: 80px !important;
			}
		}
	}
	
</style>